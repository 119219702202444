<template>
  <ValidationObserver ref="userForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label-width="labelWidth" :label="$t('fields.firstname')">
        <template slot="value">
          <base-input maxlength="30" class="mb-0 flex-grow-1" v-model="user.firstname" type="text"/>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.lastname')">
        <template slot="value">
          <base-input maxlength="30" class="mb-0 flex-grow-1" v-model="user.lastname" type="text"/>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.operator_number')">
        <template slot="value">
          <ValidationProvider class="flex-grow-1" name="username" rules="numeric|between:0,9999"
                              v-slot="{ passed, failed, errors }">

            <base-input
              maxlength="4"
              class="mb-0 flex-grow-1"
              v-model="user.operator_number"
              type="text"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"

            />
          </ValidationProvider>

        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.salary')">
        <template slot="value">
          <ValidationProvider class="flex-grow-1" name="username" rules="double:2" v-slot="{ passed, failed, errors }">
            <base-input
              maxlength="10"
              class="mb-0 flex-grow-1"
              v-model="user.salary"
              type="text"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            />
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.profile')">
        <template slot="value">
          <base-input class="mb-0 flex-grow-1">
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.profile')"
              v-model="user.profile"
            >
              <el-option
                v-for="(profile, index) in profileOptions"
                v-bind:key="`user-profile-${index}`"
                class="select-default text-uppercase"
                :value="profile"
                :label="profile"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>
    </ul>
  </ValidationObserver>
</template>

<script>
import User from "@/models/user";
import {Select, Option} from 'element-ui';
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import settingConst from "@/pages/Settings/resources/settingConst";
import {mapGetters} from "vuex";

export default {
  name: "OperatorForm",
  data() {
    return {
      labelWidth: 200
    }
  },
  components: {
    ListGroupItemComponent,
    [Option.name]: Option,
    [Select.name]: Select
  },
  props: {
    user: {
      type: Object,
      default: () => _.cloneDeep(User)
    }
  },
  methods: {
    getOperatorData: function () {
      return new Promise((resolve, reject) => {
        this.$refs.userForm.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              resolve(this.user);
            }
          })
          .catch((err) => {
            reject(err)
          });
      });
    }
  },
  computed: {
    ...mapGetters({
      settings: 'common/settings'
    }),

    profileOptions() {
      return this.$_.map(this.settings[settingConst.CALLCENTER_AWARD_APPOINTMENT], item => item.profile);
    }
  }
}
</script>

<style scoped>

</style>
